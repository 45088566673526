import React from 'react'

export default function RideThroughHistory() {
  return (
    <div>
   
    <div class="container mx-auto p-5">
        <h1 class="text-4xl font-bold text-center mb-6">Rajasthan Road Trip: Explore the Royal Heritage</h1>
        
        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-3">1. Jaipur – The Pink City</h2>
            <p>Kick off your road trip in Jaipur, the capital of Rajasthan, known for its royal heritage and pink-hued buildings. The city offers an array of attractions, starting with the majestic Amber Fort, a blend of Hindu and Mughal architecture. Drive through the bustling streets to visit the City Palace, the residence of the royal family, and the Hawa Mahal, or “Palace of Winds,” with its iconic honeycomb facade. Don’t miss the Jantar Mantar, an ancient astronomical observatory.</p>
            <p class="font-semibold mt-2">Travel Tip:</p>
            <p>Jaipur's streets can get busy, so start your day early to avoid the rush and enjoy the sights in peace.</p>
        </section>

        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-3">2. Jodhpur – The Blue City</h2>
            <p>From Jaipur, drive towards Jodhpur, the “Blue City,” famous for its indigo-colored houses and the imposing Mehrangarh Fort. Perched atop a hill, the fort offers panoramic views of the city and houses an excellent museum showcasing the royal family’s legacy. While in Jodhpur, visit the serene Jaswant Thada, a white marble cenotaph, and explore the vibrant Sardar Market for handicrafts and spices.</p>
            <p class="font-semibold mt-2">Travel Tip:</p>
            <p>The route from Jaipur to Jodhpur is about 6 hours by car, so plan for rest stops or a quick detour to visit the Ranakpur Jain Temple along the way.</p>
        </section>

        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-3">3. Udaipur – The City of Lakes</h2>
            <p>Next, head south to Udaipur, Rajasthan's romantic gem. Known as the “City of Lakes,” Udaipur is famed for its shimmering lakes and exquisite palaces. The City Palace on the shores of Lake Pichola is a sprawling complex of courtyards, pavilions, and gardens. Take a boat ride on the lake to enjoy views of the Lake Palace and Jag Mandir, both set on picturesque islands. Don't forget to visit the Saheliyon ki Bari, a lush garden built for royal maidens.</p>
            <p class="font-semibold mt-2">Travel Tip:</p>
            <p>The drive from Jodhpur to Udaipur takes about 4-5 hours. You can also make a stop at Kumbhalgarh Fort.</p>
        </section>

        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-3">4. Jaisalmer – The Golden City</h2>
            <p>Heading west, journey into the heart of the Thar Desert to reach Jaisalmer, the “Golden City.” Dominated by the Jaisalmer Fort, this living fort is home to a thriving community and offers stunning views of the desert. Explore the beautifully carved Patwon ki Haveli and the Salim Singh ki Haveli, which reflect the intricate craftsmanship of the era. For an unforgettable desert experience, take a camel safari into the Sam Sand Dunes and enjoy the sunset over the dunes.</p>
            <p class="font-semibold mt-2">Travel Tip:</p>
            <p>The route from Jodhpur to Jaisalmer can be a long one (approximately 5 hours), so make sure to carry plenty of water and fuel up before you leave.</p>
        </section>

        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-3">5. Pushkar – The Sacred City</h2>
            <p>Your road trip wouldn’t be complete without a visit to Pushkar, one of the oldest cities in India and a major pilgrimage destination. The sacred Pushkar Lake is surrounded by hundreds of temples, including the famous Brahma Temple, one of the few temples in the world dedicated to Lord Brahma. If you visit in November, the annual Pushkar Camel Fair is a vibrant cultural spectacle not to be missed.</p>
            <p class="font-semibold mt-2">Travel Tip:</p>
            <p>Pushkar is a short drive from Jaipur or Udaipur, making it a convenient stopover to enjoy some spiritual and cultural experiences.</p>
        </section>

        <section class="mb-10">
            <h2 class="text-2xl font-semibold mb-3">6. Bikaner – The Desert Oasis</h2>
            <p>A lesser-known but equally fascinating stop is Bikaner, another desert city rich in history. The impressive Junagarh Fort is a must-see, known for its intricate interiors and the grand Anup Mahal. Bikaner is also famous for its camel-breeding farms and the quirky Karni Mata Temple, often referred to as the “Rat Temple” due to the thousands of rats that inhabit it.</p>
            <p class="font-semibold mt-2">Travel Tip:</p>
            <p>Bikaner offers a unique glimpse into Rajasthan’s culture, and the scenic drive from Jaisalmer to Bikaner is a wonderful way to experience the changing desert landscape.</p>
        </section>

        <section class="mb-10">
            <img src={require('../ridethroughhistory/ridethroughhistory.jpg')} height={200} width={250} className='p-2 rounded-xl' alt="" />
            <h2 class="text-2xl font-semibold mb-3">7. Mount Abu – The Hill Station</h2>
            <p>End your journey in Rajasthan’s only hill station, Mount Abu, a cool retreat in the Aravalli Range. The highlight of Mount Abu is the Dilwara Temples, a stunning collection of Jain temples renowned for their intricate marble carvings. Enjoy a peaceful boat ride on Nakki Lake and soak in the views from Sunset Point.</p>
            <p class="font-semibold mt-2">Travel Tip:</p>
            <p>The road to Mount Abu is winding and scenic, so take your time and enjoy the cool, refreshing air as you ascend to this hilltop retreat.</p>
        </section>

        <footer class="text-center mt-10">
            <h2 class="text-2xl font-bold">Happy Travels!</h2>
            <p>A road trip through Rajasthan offers an unparalleled adventure into India’s royal past, with the freedom to explore at your own pace. From the bustling streets of Jaipur to the tranquil sands of Jaisalmer, every turn brings a new story and a deeper connection to the rich history and culture of this incredible state. So, buckle up, hit the road, and let Rajasthan unfold its treasures before you.</p>
        </footer>
    </div>
    {/* contact */}
    <div>
          <div class="bg-gradient-to-b from-orange-300 via-yellow-200 to-pink-100 min-h-screen py-10 px-6 space-y-8">
            <h1 class="text-4xl font-bold text-center text-purple-800 tracking-wide">
              Road Trip through Rajasthan
            </h1>

            <div class="bg-yellow-50 p-6 rounded-lg shadow-lg">
              <h2 class="text-2xl font-semibold text-orange-700">
                Sunset Over the Sand Dunes of Jaisalmer
              </h2>
              <p class="mt-2 text-gray-700 text-lg">
                Golden rays fading into the dunes, casting long shadows over the
                sand. The horizon blends in hues of orange and pink, painting
                the landscape with warmth and magic.
              </p>
            </div>

            <div class="bg-yellow-50 p-6 rounded-lg shadow-lg">
              <h2 class="text-2xl font-semibold text-indigo-700">
                The Vibrant Markets of Jaipur and Jodhpur
              </h2>
              <p class="mt-2 text-gray-700 text-lg">
                Bustling streets alive with color, sound, and aroma. The markets
                shimmer with handcrafted jewelry, textiles, and spices—a
                kaleidoscope of Rajasthan’s artistic soul.
              </p>
            </div>

            <div class="bg-yellow-50 p-6 rounded-lg shadow-lg">
              <h2 class="text-2xl font-semibold text-blue-700">
                The Peaceful Lakes of Udaipur at Dawn or Dusk
              </h2>
              <p class="mt-2 text-gray-700 text-lg">
                Calm waters reflecting the sky, as the first or last light of
                the day softly illuminates the city. The lakes of Udaipur
                whisper a serenity, timeless and tranquil.
              </p>
            </div>

            <div class="bg-yellow-50 p-6 rounded-lg shadow-lg">
              <h2 class="text-2xl font-semibold text-red-700">
                Traditional Rajasthani Performances
              </h2>
              <p class="mt-2 text-gray-700 text-lg">
                Vibrant folk dances and puppet shows tell tales of bravery,
                love, and folklore. With every beat of the dhol, Rajasthan’s
                soul dances in celebration.
              </p>
            </div>

            <p class="text-center text-xl text-gray-600 italic">
              These experiences are what make your road trip through Rajasthan
              truly unforgettable.
            </p>
          </div>
        </div>

    </div>
  )
}
