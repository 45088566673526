// BlogApi.js

const dummyData = [
  {
    title: "Car Tours Jodhpur",
    description:
      "Jodhpur, the Blue City of India, is a popular travel destination known for its rich heritage, grand forts, and stunning landscapes. To fully immerse yourself in its splendor, exploring the city via car tours in Jodhpur is the perfect choice. Whether you're looking to explore the historical sites or the natural beauty surrounding the city, having a reliable car hire service can make your journey more enjoyable. In this blog, we will explore the best cab services in Jodhpur, how to choose the right taxi services in Jodhpur, and what makes car rental in Jodhpur a must-have for travelers.",
    image:
      "https://lh7-rt.googleusercontent.com/docsz/AD_4nXejsY8svqzDFERndHC9QvqM639LcsSodaeigXoYDCITW3qW7tCh7OLP8suucaUKFqu8WJrS_z5MONVSe05NR7BZyZt--FHhy-sMlTz6eg1ev9r4E5nvCK4WDiEJ3SPE_vC_8FFZcxUNVkjvNOKMqp1ciL5S5GaZIIGEvKGE8BqwkJETAfKAWx8?key=TWsNh16VyJLb9lo2e5qhig",
    navigateTo: "/car-tours-jodhpur-blogs",
  },
  {
    title:
      "Exploring Jodhpur: The Ultimate taxi Service in jodhpur for Your Adventure",
    description:
      "Jodhpur, famously known as the Blue City for its stunning azure-painted buildings, is a treasure trove of history, culture, and breathtaking landscapes. Whether you’re visiting the magnificent Mehrangarh Fort, strolling through the vibrant bazaars, or indulging in local cuisine, the best way to experience all that Jodhpur has to offer is by taxi service jodhpur. Here’s why opting for a rental car is the perfect choice for your Jodhpur adventure",
    image: require("../../blog-container/blog-lists/ExploringJodhpurRoadTrip/mehraangadh.jpg"),
    navigateTo: "/exploring-Jodhpur-road-trip-taxi-in-jodhpur",
  },
  {
    title:
      "Discover the Best Car Tours in Jodhpur: A Blend of Culture, Nature, and Convenience",
    description:
      "Jodhpur, the Blue City of India, is a popular travel destination known for its rich heritage, grand forts, and stunning landscapes. To fully immerse yourself in its splendor, exploring the city via car tours in Jodhpur is the perfect choice. Whether you're looking to explore the historical sites or the natural beauty surrounding the city, having a reliable car hire service can make your journey more enjoyable. In this blog, we will explore the best cab services in Jodhpur, how to choose the right taxi services in Jodhpur, and what makes car rental in Jodhpur a must-have for travelers.",
    image: require("../../blog-container/blog-lists/DiscovertheBestCarToursinJodhpur/Jodhpur.png"),
    navigateTo: "/discover-the-Best-Car-Tours-in-Jodhpur",
  },

  {
    title: "Discover Rajasthan with Our Reliable Cab Service",
    description:
      " Jodhpur, the Blue City, is a captivating destination  with its rich history, stunning architecture, and vibrant culture. Whether you’re visiting the majestic Mehrangarh Fort, the intricate Umaid Bhawan Palace, or the bustling local markets, navigating through          the city is essential for a seamless travel experience. This is where          taxi services in Jodhpur come to your rescue, offering convenient,          comfortable, and reliable transport options for both locals and          tourists.",
    image: require("../blog-lists/dicoverwithreliablecab/images/airport-jodhpur.png"),
    navigateTo: "/exploring-Jodhpur-with-Reliable-Taxi-Services",
  },
  {
    title: "Exploring Rajasthan by Car: A Royal Road Trip Adventure",
    description:
      "Rajasthan, the land of kings, is a mesmerizing state full of vibrant culture, majestic forts, sprawling deserts, and bustling markets. One of the best ways to explore this diverse region is by embarking on a car tour. Whether you're looking to delve into the history of ancient forts or experience the vibrant local life, Rajasthan’s scenic routes promise unforgettable moments. Here's how to plan the perfect road trip across Rajasthan.",
    image: require("../blog-lists/rajasthanbycar/rajasthan-best-tour-taravel.png"),
    navigateTo: "/exploring-rajasthan-by-car-A-Royal-Road-Trip-Adventure",
  },
  {
    title:"Hidden Gems of Rajasthan You Can Explore with a Car rental jodhpur",
    description:"While the popular tourist spots like Jaipur, Udaipur, and Jodhpur are well-known, Rajasthan is also home to lesser-known but equally enchanting destinations. Car rental jodhpur gives you the flexibility to explore these hidden gems.",
    image: require("../../blog-container/blog-lists/hiddengem/bundi.jpg"),
    navigateTo:"/Hidden-Gems-of-Rajasthan-You-Can-Explore-with-a-Car-rental-jodhpur"
  },
  {
    title:"Exploring Rajasthan Through a Cab service in jodhpur: A Journey to Remember ",
    description:"Rajasthan, the Land of Kings, is a vibrant and colorful state in India that boasts of majestic forts, palaces, deserts, and rich cultural heritage. While there are many ways to explore this beautiful state, traveling through Rajasthan in a cab service in jodhpur offers unmatched freedom and flexibility. From the grand cities to the offbeat paths,cab service in jodhpur journey allows you to soak in the essence of Rajasthan at your own pace. Here's how to make the most of your cab journey through Rajasthan",
    image: require("../../blog-container/blog-lists/gerneyToRemember/umaid-bhawan.jpg"),
    navigateTo:"/Rajasthan-Through-a-Cab-service-in-jodhpur-A-Journey-to-Remember"
  },
  {
    title:"Exploring Jodhpur in Comfort with Innova Crysta by car tours Jodhpur",
    description:"When you think of Jodhpur, the first things that come to mind are the majestic forts, palaces, vibrant markets, and the beautiful blue hues of the old city. Known as the Blue City or the Jodhpur has plenty to offer its visitors. Whether you're visiting for leisure or business, comfort and convenience are paramount to making your experience enjoyable. This is where renting an Innova Crysta by car tours Jodhpur can elevate your trip",
    image: require("../../blog-container/blog-lists/inovacrysta/inova.jpg"),
    navigateTo:"/exploring-jodhpur-in-comfort-with-innova-crysta-by-car-tours-jodhpur"
  },
  {
    title:"Ride Through History: Rajasthan's Top Sights by Car tours jodhpur",
    description:"Rajasthan, the Land of Kings, is a stunning tapestry of rich history, royal palaces, and vast desert landscapes. To truly experience the grandeur and hidden gems of this state, nothing beats the freedom and adventure of a road trip. By car tours jodhpur, you can navigate through the golden sands, explore magnificent forts, and discover the vibrant culture that thrives in every corner. Here’s a guide to the top must-visit sights in Rajasthan, perfect for your next driving adventure.",
    image: require("../../blog-container/ridethroughhistory/ridethroughhistory.jpg"),
    navigateTo:"/ride-through-history-Rajasthan's-Top-Sights-by-Car-tours-jodhpur"
  },
  {
    title:"Exploring Jodhpur: Your Ultimate Guide to Car Tours",
    description:"Jodhpur, often referred to as the Blue City due to its striking blue-painted houses, is a treasure trove of history and culture. To fully experience the city’s wonders, embarking on a car tour is the best option. With various car hire services, cab options, and rental vehicles available, you can explore Jodhpur with car tour jodhpur. Let’s dive deeper into the advantages of car tours in Jodhpur and the various services that make your journey seamless",
    image: require("../../blog-container/blog-lists/UltimateGuideToCarsJodhpur/clocktowerjodhput.jpg"),
    navigateTo:"/exploring-jodhpur-Your-Ultimate-Guide-to-Car-Tours"
  },
  {
    title:"Discover Rajasthan on an Unforgettable Car Tour from Jodhpur",
    description:"Rajasthan, the land of kings, is known for its vibrant culture, royal palaces, ancient forts, and enchanting deserts. A road trip through Rajasthan is a journey through history, and what better way to explore it than by car? With car rental in Jodhpur, you can travel comfortably and conveniently, immersing yourself in the charm of Rajasthan at your own pace Whether you’re traveling with family, friends, or solo, hiring a cab service offers flexibility and freedom. Here’s why a car tour from Jodhpur is the ultimate way to experience the beauty of Rajasthan",
    image: require("../../blog-container/blog-lists/Unforgettable/unnamed (1).jpg"),
    navigateTo:"/Discover-Rajasthan-on-an-Unforgettable-Car-Tour-from-Jodhpurs"
  },
  {
    title:"Explore Jodhpur: The Blue City’s Iconic Landmarks & Taxi Services",
    description:"Jodhpur, famously known as the Blue City and the Sun City of India, is a must-visit destination for history lovers, culture enthusiasts, and explorers. With its blue-washed houses, grand forts, and vibrant bazaars, the city offers a unique blend of ancient charm and royal heritage. When visiting Jodhpur, exploring its many iconic places becomes much easier and more convenient with reliable taxi services in Jodhpur. Here’s a guide to the top places you can visit in the city and how taxi services make your journey more enjoyable",
    image: require("../../blog-container/blog-lists/iconic-landmark/turji-ka-jaalra.jpg"),
    navigateTo:"/Explore-Jodhpur-The-Blue-City’s-Iconic-Landmarks-Taxi-Services"
  },
  {
    title:"Discover Jodhpur with Ease: Unmatched Car Hire and Rental Services",
    description:"Exploring Jodhpur, with its rich history, architectural marvels, and vibrant culture, is an experience that deserves to be enjoyed to the fullest. Whether you're marveling at the iconic Mehrangarh Fort or immersing yourself in the local markets, our premium Car Rental Jodhpur services ensure that your journey is not only convenient but also memorable . Car Tours Jodhpur for Every Traveler",
    image: require("../../blog-container/blog-lists/iconic-landmark/turji-ka-jaalra.jpg"),
    navigateTo:"/Discover-Jodhpur-with-Ease-Unmatched-Car-Hire-and-Rental-Services"
  },
  // /Top-10-Reasons-to-Choose-a-Jodhpur-Taxi-Service-for-Your-Next-Trip
  {
    title:"Top 10 Reasons to Choose a Jodhpur Taxi Service for Your Next Trip",
    description:"Exploring Jodhpur, with its rich history, architectural marvels, and vibrant culture, is an experience that deserves to be enjoyed to the fullest. Whether you're marveling at the iconic Mehrangarh Fort or immersing yourself in the local markets, our premium Car Rental Jodhpur services ensure that your journey is not only convenient but also memorable . Car Tours Jodhpur for Every Traveler",
    image: require("../../blog-container/blog-lists/iconic-landmark/turji-ka-jaalra.jpg"),
    navigateTo:"/Top-10-Reasons-to-Choose-a-Jodhpur-Taxi-Service-for-Your-Next-Trip"
  },
  {
    title:"Luxury on Wheels: Rent an Innova Crysta in Jodhpur Today",
    description:"Jodhpur, also known as the Blue City and the Gateway to Thar, is a destination brimming with history, royalty, and cultural heritage. Whether youre visiting for leisure, business, or a quick getaway, traveling in comfort is a priority to experience luxury, space, and style while exploring the city majestic landmarks.",
    image: require("../../blog-container/blog-lists/iconic-landmark/turji-ka-jaalra.jpg"),
    navigateTo:"/Luxury-on-Wheels-Rent-an-Innova-Crysta-in-Jodhpur-Today"
  },
  // Navigate the Wonders of Jodhpur
  {
    title:"Navigate the Wonders of Jodhpur: Your Guide to the Best Car Hire Options",
    description:"Jodhpur, famously known as the Blue City and the Gateway to Thar, offers breathtaking landscapes, historical forts, and cultural experiences. To make your trip stress-free, choosing thembest car hire optionsm is crucial. Whether you’re looking for amJodhpur taxi service day tourm or need a ride formoutstation tripsm, this guide will help you navigate the top options",
    image: require("../../blog-container/blog-lists/navigarejodhpurwonders/Picture1.jpg"),
    navigateTo:"/Navigate-the-Wonders-of-Jodhpur"
  },
];

export const fetchBlogData = async () => {
  return dummyData;
};
