import React from "react";
import { useNavigate } from "react-router-dom"; 
import noblogavailable from "../../../assets/blog.png"


const MainBlogContainer = ({ blogData }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleContainerClick = (url) => {
    navigate(url); 
  };

  if (!Array.isArray(blogData) || blogData.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        {/* Decorative Image */}
        <img
          src={noblogavailable}
          alt="No Blogs"
          className="w-40 h-40 mb-5 rounded-full object-cover shadow-lg"
        />
        {/* No Blogs Text */}
        <div className="text-center">
          <h2 className="text-4xl font-bold text-gray-700 mb-3">
            No Blogs Available
          </h2>
          <p className="text-lg text-gray-500">
            We're sorry, but we couldn't find any blogs at the moment.
          </p>
          <p className="text-lg text-gray-500">
            Please check back later for updates!
          </p>
        </div>
      </div>
    );
  }
  

  return (
    <div className="w-full h-full px-4 py-6 max-w-7xl mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {blogData.map((post, index) => (
        <div
          key={index}
          className="border border-gray-200 shadow-md rounded-lg overflow-hidden flex flex-col justify-between transition-transform duration-300 hover:scale-105 cursor-pointer"
          onClick={() => handleContainerClick(post.navigateTo)} // Handle click
        >
          <div>
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-[220px] object-cover"
            />
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2 transition-colors duration-300 hover:text-orange-500">
                {post.title}
              </h2>
              <p className="text-gray-700 mb-3 line-clamp-3 md:line-clamp-4 transition-colors duration-300 hover:cursor-pointer">
                {post.description}
              </p>
            </div>
          </div>
          <div className="p-4">
            <button className="px-4 py-2 bg-blue-500 text-white rounded transition-all duration-300 hover:bg-blue-600 hover:shadow-lg">
              Read More
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
  
  );
};


export default MainBlogContainer;
