import React from "react";
import styled from "styled-components";
import toorjikajaalra from "../iconic-landmark/turji-ka-jaalra.jpg";
import mandorejodhpur from "../iconic-landmark/mandore-jodhpur.jpg";
import hutjodhpur from "../iconic-landmark/hut-jodhpur.png";
import clocktowerjodhpur from "../iconic-landmark/clock-towe-jodhpur.jpg";

const StyledImage = styled.img`
  max-width: 40%;
  padding: 10px;
  max-height: 400px;
  border-radius: 30px;

  @media (max-width: 768px) {
    max-height: 1000px;
    max-width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export default function IconicLandmark() {
  return (
    <div>
      <div class="container mx-auto px-4 py-8">
        <h1 class="text-4xl font-bold text-center mb-6">
        Explore Jodhpur: The Blue City’s Iconic Landmarks & Taxi Services

        </h1>
        <p className="mx-4">Jodhpur, famously known as the Blue City and the Sun City of India, is a must-visit destination for history lovers, culture enthusiasts, and explorers. With its blue-washed houses, grand forts, and vibrant bazaars, the city offers a unique blend of ancient charm and royal heritage. When visiting Jodhpur, exploring its many iconic places becomes much easier and more convenient with reliable taxi services in Jodhpur. Here’s a guide to the top places you can visit in the city and how taxi services make your journey more enjoyable.</p>

        <div class="space-y-8">
          <div class="bg-white p-6 rounded-lg shadow-lg">
            <h2 class="text-2xl font-semibold">1. Mehrangarh Fort</h2>
            <p>
              One of the largest forts in India, Mehrangarh Fort stands tall
              over the city, offering breathtaking views of the blue-painted
              houses below. Built around 1459 by Rao Jodha, this architectural
              marvel is a must-visit for anyone interested in the history of
              Jodhpur. The fort houses intricate palaces, courtyards, and a
              museum with rare artifacts.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              Our taxis are the most convenient way to reach Mehrangarh Fort,
              especially since the fort is located on a hill, and the roads can
              be steep. Booking our car tours ensures you reach the fort without
              hassle, and your driver can wait for you until you’re ready to
              continue exploring.
            </p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg">
            <h2 class="text-2xl font-semibold">2. Umaid Bhawan Palace</h2>
            <p>
              A symbol of Jodhpur's royal legacy, the Umaid Bhawan Palace is one
              of the world’s largest private residences and is still home to the
              royal family. Part of the palace has been converted into a luxury
              hotel, while another section is a museum that showcases royal
              artifacts, vintage cars, and historical relics.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              Reaching Umaid Bhawan Palace via car tours ensures a comfortable
              ride to this iconic landmark. The palace is located on the
              outskirts of the city, so having car tours allows you to enjoy the
              journey while soaking in the city’s panoramic views.
            </p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg">
            <h2 class="text-2xl font-semibold">3. Jaswant Thada</h2>
            <p>
              Just a short distance from Mehrangarh Fort lies Jaswant Thada, an
              elegant marble cenotaph built in memory of Maharaja Jaswant Singh
              II. The intricate marble latticework, quiet surroundings, and
              stunning views of the fort make this place ideal for a peaceful
              visit.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              The serene location of Jaswant Thada makes it somewhat challenging
              to reach using public transport. By opting for car tours, you can
              travel directly from the fort or the city center in a comfortable
              and timely manner.
            </p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg">
            <StyledImage src={mandorejodhpur} alt="mandore-jodhpur" />
            <h2 class="text-2xl font-semibold">4. Mandore Gardens</h2>
            <p>
              For a relaxed day out, head to the beautiful Mandore Gardens,
              located just outside the city. Known for its collection of royal
              cenotaphs (chhatris), temples, and lush gardens, this spot is a
              perfect mix of nature and history.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              Mandore Gardens is located about 9 km from the city center, so
              taking a car tour is the best way to travel without worrying about
              parking or navigating public transportation.
            </p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg">
            <h2 class="text-2xl font-semibold">
              5. Toorji Ka Jhalra (Stepwell)
            </h2>
            <StyledImage src={toorjikajaalra} />
            <p>
              One of Jodhpur’s hidden gems, Toorji Ka Jhalra is an ancient
              stepwell that dates back to the 18th century. Restored to its
              original beauty, the stepwell is a striking sight with its
              geometric architecture and intricate stone carvings.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              The narrow streets around Toorji Ka Jhalra can be challenging to
              navigate. Car tours can drop you off nearby, ensuring you have
              easy access to the stepwell and surrounding attractions.
            </p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg">
            <h2 class="text-2xl font-semibold">
              6. Rao Jodha Desert Rock Park
            </h2>
            <p>
              Adjacent to Mehrangarh Fort, the Rao Jodha Desert Rock Park was
              created to restore the natural ecology of a large, rocky
              wasteland. This park showcases the natural beauty of desert flora.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              Since the park is located close to Mehrangarh Fort, car tours can
              easily shuttle you between these attractions, making it convenient
              to explore both on the same day.
            </p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg">
            <h2 class="text-2xl font-semibold">
              7. Clock Tower and Sardar Market
            </h2>
            <StyledImage src={clocktowerjodhpur} alt="clock-tower-jodhpur" />
            <p>
              For a taste of local culture and shopping, head to the Clock Tower
              and Sardar Market, where you’ll find everything from spices and
              textiles to antiques and handicrafts.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              Parking near the busy market can be a hassle. By hiring car tours,
              you can be dropped off right at the market and picked up when
              you’re done shopping.
            </p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg">
            <StyledImage src={hutjodhpur} alt="hut-jodhpur" />
            <h2 class="text-2xl font-semibold">8. Bishnoi Village Safari</h2>
            <p>
              For a unique experience that goes beyond Jodhpur’s city limits,
              embark on a Bishnoi Village Safari. The Bishnoi community is known
              for its love of nature and wildlife.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              A day trip to Bishnoi Village requires transportation as it’s
              located around 25 km from Jodhpur. Hiring car tours ensures you
              have a comfortable ride to and from the village.
            </p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg">
            <h2 class="text-2xl font-semibold">
              9. Osian Temples and Sand Dunes
            </h2>
            <p>
              A short drive from Jodhpur, Osian is a town known for its ancient
              temples and desert sand dunes. It’s a great place to experience
              both culture and adventure.
            </p>
            <h3 class="font-semibold mt-4">Why Visit by Car Tours Jodhpur?</h3>
            <p>
              Given that Osian is about 60 km from Jodhpur, car tours service is
              the most convenient option for this day trip.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
